<template>
    <div id="profile-page">
        <!-- COL AREA -->
        <div class="vx-row">
            <!-- COL 1 -->
            <div class="vx-col w-full lg:w-1/4 lg:order-1 order-2 hidden-sm hidden-md hidden-ip">
                <AboutUser v-if="!isPartner" />
              
            </div>
            <!-- COL 2 -->
            <EditPostForm ref="editPostForm" @postEditFinished="updatePost" />
            <div class="vx-col w-full md:w-full lg:w-full xl:w-1/2 lg:order-2 order-1">
              <Post 
                v-if="currentPost" 
                :feed="currentPost"  
                :actor="''"
                :action="''"
                :displayComment="true"  
                :displayPostOption="true"
                :displayLikeAndShare="true"
                :displayShare="true"
                :displayLikeAndCommentCount="true"
               
                @toggleEditPostForm="toggleEditPostForm(currentPost)"
                />
            </div>
            <!-- COL 3 -->
            <div class="vx-col w-full lg:w-1/4 lg:order-3 order-3 hidden-sm hidden-md hidden-ip">
            <vx-card title="Suggested Follows" class="mt-base"> 
                <SuggestedFollows />
            </vx-card>
            </div>
        </div>
    </div>
</template>

<script>

import MyFeeds from '@/layouts/components/coursepal/MyFeeds'
import SuggestedFollows from '@/layouts/components/coursepal/SuggestedFollows'

import Post from '../../layouts/components/coursepal/Post/Post'
import EditPostForm from '@/layouts/components/coursepal/EditPostForm'
import AboutUser from '@/layouts/components/coursepal/AboutUser'

export default {
  name: 'SinglePost',
  watch: {
    '$route.query.postId' (id) {
      this.$store.dispatch('newsfeed/getPostById', id)
    },
    currentPost(newVal){
       this.currentPost=newVal
    }
  },
  data () {
    return {
      isNavOpen: false,
      wasSidebarOpen: null,
      // currentPost: this.$store.state.newsfeed.currentPost
    }
  },
  computed: {
    isPartner () {
      return this.$store.state.auth.userData.isPartner
    },
    currentPost: {
      // getter
      get() {
        return this.$store.state.newsfeed.currentPost
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
       return console.log(newValue)
      }
    }
    
    // currentPost (){ this.$store.state.newsfeed.currentPost}
  },
  components: {
    AboutUser,

    MyFeeds,
    SuggestedFollows,

    Post,
    EditPostForm
  },
  methods: {
    toggleEditPostForm (feed) { 
      // set edit post form data over here
      this.$refs.editPostForm.feed = feed
      this.$refs.editPostForm.isActive = true
      this.$refs.editPostForm.$refs.editPostTextarea.$el.innerText = feed.content
      this.$refs.editPostForm.$refs.editPostTextarea.createHashtagElement()
    },
    updatePost () {
      this.currentPost = this.$store.state.newsfeed.currentPost
      this.$refs.editPostForm.isActive = false
      
    }
  },
  mounted () { 
    this.wasSidebarOpen = this.$store.state.reduceButton
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  },
  beforeDestroy () {
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
  },
  created () {
    this.$store.dispatch('newsfeed/getPostById', this.$route.query.postId).then(() => {
      this.currentPost = this.$store.state.newsfeed.currentPost
    })
  }
}

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
